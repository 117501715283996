/**
 * This takes the config and return the correctly formated clickToDialLayout
 * @param config Config returned from initializedComplete
 */
export function getClickToDialLayout(config) {
  const layout = {
    Entities: []
  };
  if (config && config['ClickToDialLayout']) {
    for (const entity of Object.keys(config.ClickToDialLayout)) {
      if (entity === 'variables') {
        continue;
      }

      const object = {
        Name: entity,
        PhoneFields: null,
        RelatedEntities: null
      };
      layout.Entities.push(object);

      if (config.ClickToDialLayout[entity].PhoneFields) {
        object.PhoneFields = [];
        for (const field of Object.keys(
          config.ClickToDialLayout[entity].PhoneFields
        )) {
          if (
            config.ClickToDialLayout[entity].PhoneFields[field].variables &&
            config.ClickToDialLayout[entity].PhoneFields[field].variables
              .DisplayName &&
            config.ClickToDialLayout[entity].PhoneFields[field].variables.APIName
          ) {
            object.PhoneFields.push({
              DisplayName:
                config.ClickToDialLayout[entity].PhoneFields[field].variables
                  .DisplayName,
              APIName:
                config.ClickToDialLayout[entity].PhoneFields[field].variables
                  .APIName
            });
          }
        }
      }

      if (config.ClickToDialLayout[entity].RelatedEntities) {
        object.RelatedEntities = [];
        for (const relatedEntity of Object.keys(
          config.ClickToDialLayout[entity].RelatedEntities
        )) {
          if (
            config.ClickToDialLayout[entity].RelatedEntities[relatedEntity]
              .variables &&
            config.ClickToDialLayout[entity].RelatedEntities[relatedEntity]
              .variables.EntityName &&
            config.ClickToDialLayout[entity].RelatedEntities[relatedEntity]
              .variables.FieldName
          ) {
            object.RelatedEntities.push({
              EntityName:
                config.ClickToDialLayout[entity].RelatedEntities[relatedEntity]
                  .variables.EntityName,
              FieldName:
                config.ClickToDialLayout[entity].RelatedEntities[relatedEntity]
                  .variables.FieldName
            });
          }
        }
      }
    }
  }
  return layout;
}
