import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit,
  OnChanges
} from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { StorageService } from '../storage.service';
import { LoggerService } from './../logger.service';
import { ISearchLayout } from '../Model/ISearchLayout';
@Component({
  selector: 'app-caller-information',
  templateUrl: './caller-information.component.html',
  styleUrls: ['./caller-information.component.css']
})
export class CallerInformationComponent implements OnChanges {
  @Input() searchLayout: ISearchLayout;
  @Input() searchRecordList: Array<api.IRecordItem>;
  @Output() agentSelectedCallerInformation: EventEmitter<api.IRecordItem> = new EventEmitter();
  maximizeSearchInformation: boolean;
  imageLocation: string;
  singleMatchIconSrc: string;
  singleMatchData: any;
  multiMatchData: any;
  shouldShowAllMultiMatchOptions: boolean;

  constructor(
    protected storageService: StorageService,
    private loggerService: LoggerService
  ) {
    this.maximizeSearchInformation = true;
  }
  ngOnChanges() {
    this.renderData();
  }
  protected renderData() {
    try {
      this.loggerService.logger.logLoop('SAPCRMApp: Search : START : Render Data called');
      this.singleMatchIconSrc = '';
      this.multiMatchData = [];
      this.shouldShowAllMultiMatchOptions = false;
      if (this.searchRecordList.length === 1) {
        this.singleMatchData = this.parseSearchRecordForNameSingleMatch(this.searchRecordList[0]);
      } else if (this.searchRecordList.length > 1) {
        for (let i = 0; i < this.searchRecordList.length; i++) {
          this.multiMatchData.push(
            this.parseSearchRecordForNameMultiMatch(this.searchRecordList[i])
          );
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SAPCRMApp: Search : ERROR : Render Data. Error Information : '
      + JSON.stringify(error));
    }
    this.loggerService.logger.logLoop('SAPCRMApp: Search : END : Render Data called');
  }
  protected collapseCallerInfoResults() {
    this.shouldShowAllMultiMatchOptions = false;
  }
  protected expandCallerInfoResults() {
    this.shouldShowAllMultiMatchOptions = true;
  }

  protected resizeSearchInformation(size: string) {
    if (size === 'collapse') {
      this.loggerService.logger.logDebug(
        'searchInformationComponent: collapse window',
        api.ERROR_CODE.SEARCH_RECORD
      );
      this.maximizeSearchInformation = false;
    } else {
      this.loggerService.logger.logDebug(
        'searchInformationComponent: expand window',
        api.ERROR_CODE.SEARCH_RECORD
      );
      this.maximizeSearchInformation = true;
    }
  }

  protected resizeSearchInformationKeyboard(event: KeyboardEvent, size: string) {
    if (event.key === 'Enter') {
      this.resizeSearchInformation(size);
    }
  }


  protected onAgentSelectedCallerInformation(event: any) {
    try {
      this.loggerService.logger.logTrace('SAPCRMApp: Search : START : On Agent Selected Caller Information');
      if (this.searchRecordList.length > 1) {
        this.storageService.selectedSearchRecordList[this.storageService.currentScenarioId] = event.currentTarget.id;
      }
      this.agentSelectedCallerInformation.emit(
        this.searchRecordList.find(i => i.id === event.currentTarget.id)
      );
    } catch (error) {
      this.loggerService.logger.logError('SAPCRMApp: Search : ERROR : On Agent Selected Caller Information. Input : '
      + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('SAPCRMApp: Search : END : On Agent Selected Caller Information');
  }

  protected onAgentSelectedCallerInformationKeyboard(event: KeyboardEvent, event2: any) {
    if (event.key === 'Enter') {
      this. onAgentSelectedCallerInformation(event2);
    }
  }

  protected parseSearchRecordForNameSingleMatch(searchRecord: api.IRecordItem) {
    this.loggerService.logger.logTrace('SAPCRMApp: Search : START : Parse Search Record For Name - Single Match');
    const results = [];
    try {
      const src = this.getEntityImgToDisplay(searchRecord);
      this.singleMatchIconSrc = src;
      const sLayoutInfo = this.searchLayout.objects.filter(e => e.objectName === searchRecord.displayName);
      if (!(sLayoutInfo.length > 0 && sLayoutInfo[0].objectFields.length > 0)) {
        return;
      }
      for (let j = 0; j < sLayoutInfo[0].objectFields.length; j++) {
        if (sLayoutInfo[0].objectFields && sLayoutInfo[0].objectFields[j].APIName) {
          const nameKey = sLayoutInfo[0].objectFields[j].APIName;
          const keys = Object.keys(searchRecord.fields);
          for (let i = 0; i < keys.length; i++) {
            if (
              searchRecord.fields[keys[i]] &&
              searchRecord.fields[keys[i]].DevName === nameKey
            ) {
              let displayRecord = searchRecord.fields[keys[i]].Value;
              if (displayRecord) {
                if (j === 0) {
                  displayRecord = searchRecord.displayName
                    ? [searchRecord.displayName, displayRecord]
                    : [searchRecord.type, displayRecord];
                } else {
                  displayRecord = sLayoutInfo[0].objectFields[j].DisplayName
                    ? [sLayoutInfo[0].objectFields[j].DisplayName, displayRecord]
                    : [sLayoutInfo[0].objectFields[j].APIName, displayRecord];
                }
                results.push(displayRecord);
              }
            }
          }
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SAPCRMApp: Search : ERROR : Parse Search Record For Name - Single Match. Input : '
      + JSON.stringify(searchRecord) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('SAPCRMApp: Search : END : Parse Search Record For Name - Single Match');
    return results;
  }

  protected parseSearchRecordForNameMultiMatch(searchRecord: api.IRecordItem) {
    this.loggerService.logger.logTrace('SAPCRMApp: Search : START : Parse Search Record For Name - Multi Match');
    const results = [];
    try {
      const src = this.getEntityImgToDisplay(searchRecord);
      const sLayoutInfo = this.searchLayout.objects.filter(e => e.objectName === searchRecord.displayName);
      if (!(sLayoutInfo.length > 0 && sLayoutInfo[0].objectFields.length > 0)) {
        return;
      }

      for (let j = 0; j < sLayoutInfo[0].objectFields.length; j++) {
        if (sLayoutInfo[0].objectFields && sLayoutInfo[0].objectFields[j].APIName) {
          const nameKey = sLayoutInfo[0].objectFields[j].APIName;
          const keys = Object.keys(searchRecord.fields);
          for (let i = 0; i < keys.length; i++) {
            if (
              searchRecord.fields[keys[i]] &&
              searchRecord.fields[keys[i]].DevName === nameKey
              && searchRecord.fields[keys[i]].Value
            ) {
              let displayRecord = searchRecord.fields[keys[i]].Value;
              if (j === 0) {
                displayRecord = searchRecord.displayName
                  ? [searchRecord.displayName, displayRecord]
                  : [searchRecord.type, displayRecord];
              } else {
                displayRecord = sLayoutInfo[0].objectFields[j].DisplayName
                  ? [sLayoutInfo[0].objectFields[j].DisplayName, displayRecord]
                  : [sLayoutInfo[0].objectFields[j].APIName, displayRecord];
              }
              displayRecord.push(src);
              results.push(displayRecord);
              return results;
            }
          }
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SAPCRMApp: Search : ERROR : Parse Search Record For Name - Multi Match. Input : '
      + JSON.stringify(searchRecord) + '. Error Information : '
      + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('SAPCRMApp: Search : END : Parse Search Record For Name - Multi Match');
    return results;
  }
  protected getEntityImgToDisplay(searchRecord: api.IRecordItem) {
    let src = '';
    this.loggerService.logger.logTrace('SAPCRMApp: Search : START : Get Entity Image to Display');
    try {
      if (searchRecord.type) {
        if (searchRecord.type.toUpperCase() === 'CONTACT') {
          src = '../../assets/images/Icon_Contact.png';
        } else if (searchRecord.type.toUpperCase() === 'ACCOUNT') {
          src = '../../assets/images/Icon_Account.png';
        } else if (searchRecord.type.toUpperCase() === 'LEAD') {
          src = '../../assets/images/Icon_Lead.png';
        } else {
          src = '../../assets/images/Miscellaneous_Icon.png';
        }
      }
    } catch (error) {
      this.loggerService.logger.logError('SAPCRMApp: Search : ERROR : Get Entity Image to Display. Input : '
      + JSON.stringify(searchRecord) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('SAPCRMApp: Search : END : Get Entity Image to Display');
    return src;
  }
  protected getRecord(id: string) {
    for (let i = 0; i < this.searchRecordList.length; i++) {
      if (this.searchRecordList[i].id === id) {
        return this.searchRecordList[i];
      }
    }
  }
}
